/* You can add global styles to this file, and also import other style files */


/// general CSS
* {
    font-family:"Cairo", sans-serif !important;
    
}
.ant-tag {
    height: 40px;
    border-radius: 20px;
    color: #cce7e1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;

    &.new {
        background: #fcf1cc;
        color: #EFB702;
    }
    &.parked {
        background: #ccf0f7;
        color: #00B2D5;
    }
    &.request {
        background: #fae5cc;
        color: #E67B02;
    }
    &.ready {
        background: #f1d0ed;
        color: #B814A4;
    }
    &.delivered {
        background: #cce7e1;
        color: #00856B;
    }
}

:dir(rtl) {
    *{
        font-family: 'Cairo', sans-serif !important;

    }
    button.ant-modal-close{
        padding-bottom: 4px;
    }
    .ant-modal-confirm-btns {
        button{
            &:last-child{
                margin-inline-start: 8px;
            }
        }
    }

  }
  
.ant-modal{
    .ant-modal-content {
        overflow: inherit !important;
        button.ant-modal-close{
            top: -10px !important;
            inset-inline-end: -10px !important;
        }
    }
    .ant-modal-confirm-btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.page-container {
    padding: 40px 70px;
    height: 100%;

    @media (max-width:768px) {
        padding: 0px;
        background: #f6f8fc;
        // padding-top: 60px !important;
    }
}

:dir(rtl) {
    .ant-modal-wrap .ant-modal{
        direction: rtl !important;
    }
    .ant-tabs-tabpane-active{
        .ant-tabs-tabpane{
            width: 100%;
        }
    }
}


@font-face {
    font-family: Demi;
    src: url(/assets/fonts/URWDIN-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: Demi;
    src: url(/assets/fonts/URWDIN-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Demi;
    src: url(/assets/fonts/URWDIN-Medium.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Demi;
    src: url(/assets/fonts/URWDIN-Demi.ttf);
    font-weight: 700;
}

@font-face {
    font-family: Demi;
    src: url(/assets/fonts/URWDIN-Regular.ttf);
    font-weight: 200;
}

@font-face {
    font-family: Demi;
    src: url(/assets/fonts/URWDIN-Thin.ttf);
    font-weight: 100;
}

//customize table
.custom-table {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        border-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }

    .ant-table-pagination-right {
        justify-content: center;
    }

    .ant-table-container {
        @media (max-width: 767px) {
            width: 94%;
            overflow: hidden;
            margin: 15px auto;
            margin-top: 0px !important;
            margin-bottom: 0px;
        }
    }

    .filters {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
        }
        .ant-select{
            margin-inline-end: 20px;
            width: fit-content;
            height: 40px !important;
            .ant-select-selector{
                background-color: transparent;
                border:0px;
                color: #fff;
                font-size: 18px;
                padding-top: 4px;
            }
            .ant-select-arrow{
                top: 16px !important;
                color: #fff;
                margin-top: 0px !important;
            }
            .ant-select-selector{
                height: 40px !important;
                position: relative;
                top:5px;
            }
        }
        

        .ant-dropdown-link {
            color: #fff;
            font-size: 18px;
            padding-inline-end: 30px;
        }

        .sort {
            color: #fff;
            font-size: 18px;
            padding-inline-end: 30px;
            margin: 0px;
            cursor: pointer;

        }
        .ant-select-selection-placeholder{
                color: #fff !important;
        }
    }

    .ant-table-content {
        padding: 0px 30px;
        overflow: auto;
        @media (max-width: 767px) {
            padding: 0px;
            overflow-x: scroll;
        }

        &:-webkit-scrollbar {
            display: none;
        }
    }

    .ant-table-content::-webkit-scrollbar {
        display: none;
    }

    th,
    td {
        @media (max-width: 767px) {
            font-size: 13px !important;
            padding: 10px !important;
            white-space: nowrap;
            text-align: center !important;
            height: 45px !important;
        }
    }

    .table-header {
        background: #2B50AA;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;
        padding: 0px 40px;

        @media (max-width: 767px) {
            padding: 10px 20px;
            height: fit-content;
            background: transparent;
            border-bottom-right-radius: 20px !important;
            border-bottom-left-radius: 20px !important;
            padding-top: 15px;
        }


        h2 {
            color: #fff;
            margin-bottom: 0px;
            padding-top: 4px;

            @media (max-width: 767px) {
                color: #2B50AA;
                text-transform: uppercase;
                font-size: 18px !important;

            }
        }

        .ant-btn.ant-btn-round.ant-btn-lg {
            background: #fff;
            color: #2B50AA;
            padding-top: 8px;
            align-items: center;
            justify-content: center;
        }
    }

    .ant-tag {
        width: fit-content !important;
        height: fit-content !important;
        padding: 1px 10px;
        padding-top: 3px;
        margin: 0px;
    }

    .ant-table {
        overflow: hidden;
        padding-bottom: 10px;

        @media (max-width: 767px) {
            padding-bottom: 0px;
            background: transparent;
        }

        .ant-table-row {
            padding: 40px 0px;

            @media (max-width: 767px) {
                padding: 0px;
            }

            .ant-table-cell {
                font-weight: 400;
                font-size: 14px;
                border-bottom-color: #bfcae4;
                text-align: start;
            }
        }

        .ant-table-tbody {
            .ant-table-row {
                &:last-child {
                    .ant-table-cell {
                        border: 0px !important;
                    }
                }
            }

        }

        .ant-table-thead {
            .ant-table-cell {
                font-size: 16px;
                background: #fff;
                color: #2B50AA;
                border-bottom-color: #96a7d4;
                text-align: start;

                @media (max-width: 767px) {
                    background: transparent;
                }

                &::before {
                    display: none;
                }
            }
        }

    }

    .ant-table-pagination.ant-pagination {
        padding-inline-start: 30px;
        margin: 20px 0px;
        position: relative;
        margin: 0px;
        padding: 15px 0px;
        padding-bottom: 25px;
        background: #fff;

        @media (max-width: 767px) {
            background: transparent;
        }
        .ant-select-selector {
            height: auto !important;
        }

        .ant-pagination-item {
            a {
                color: #2B50AA;
                font-size: 14px;
                line-height: 24px;
                padding: 0px;
            }
        }

        .ant-pagination-item-active {
            background: #2B50AA;

            a {
                color: #fff;
            }
        }

        ul {
            li {
                height: 24px;
                width: 20px;
                min-width: 20px;
                border-width: 2px;
                border-color: #2B50AA;
                border-radius: 5px;

                .ant-pagination-options-size-changer {
                    position: absolute;
                   inset-inline-start: 30px;
                    top: 12px;

                    .ant-select-selector {
                        border: 2px solid #2B50AA;
                        color: #2B50AA;
                        border-radius: 20px;
                    }

                    .ant-select-selection-item {
                        line-height: 30px;
                    }

                    .anticon {
                        color: #2B50AA;
                    }
                }

                button {
                    border-width: 2px;
                    border-color: #2B50AA;
                    border-radius: 5px;
                }

                .anticon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .ant-pagination-item-link {
            color: #2B50AA;
            border-color: #2B50AA;
        }
    }
}


// @media (max-width:767px) {

//     .ant-table-thead>tr>th,
//     .ant-table-tbody>tr>td,
//     .ant-table tfoot>tr>th,
//     .ant-table tfoot>tr>td {
//         padding: 8px 0px 0px 0px !important;
//     }

//     .custom-table .ant-table-content {
//         padding: 0px;
//     }

//     .custom-table .ant-table .ant-table-thead .ant-table-cell {
//         font-size: 9pt !important;
//         white-space: nowrap !important;
//         padding: 1px !important;
//     }

//     .custom-table .ant-table-pagination.ant-pagination {
//         display: flex !important;
//         flex-direction: column !important;
//     }

//     .custom-table .ant-table .ant-table-row .ant-table-cell {
//         font-size: 13pt;
//         padding-inline-end: 4px !important;
//         padding-inline-start: 4px !important;
//     }
// }




.anticon.anticon-down {
    margin-inline-start: 5px;
}


/// Design for widget
.widget {
    overflow: hidden;
    border-radius: 20px;

    @media (max-width:768px) {
        border-radius: 0 !important;
        background-color: #F6F8FC;
    }

    .widget-header {
        background: #2B50AA;
        color: #fff;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 40px;

        @media (max-width:768px) {
            background: transparent;
            padding: 11px 20px !important;
            height: fit-content;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }


        .ant-btn.ant-btn-round.ant-btn-lg {
            background: #fff;
            color: #2B50AA;
            padding-top: 8px;
        }

        .widget-title {
            padding-top: 5px;
            margin: 0px;
            font-size: 20px;
            color: #fff;

            @media (max-width:768px) {
                text-transform: uppercase;
                font-size: 18px;
                color: #2B50AA;
            }

        }

        .widget-icon {
            margin-inline-end: 7px;
        }
    }

    .widget-body {
        background: #fff;
        padding: 40px;

        @media (max-width:768px) {
            padding: 0 !important;
            background-color: #F6F8FC !important;
        }
    }
}

// customize card to be rounded and without border
.ant-card {
    border-radius: 20px;
    border: 0px;

    &.gray {
        background: #fafafa;
    }

    &.white {
        background: #fff;
    }
}

/// custom title
.custom-title {
    color: #2B50AA;
    position: relative;
    display: flex;
    font-size: 18px;
    padding-inline-start: 59px;
    font-weight: 600;

    &:before {
        content: "";
        width: 39px;
        height: 4px;
        border-radius: 10px;
        background: #2B50AA;
        display: flex;
        position: absolute;
        inset-inline-start: 0px;
        top: 10px;
    }
}

// customize input to be rounded 
.input-widget {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-bottom: 24px;

    .input-error {
        color: #f01212;
        font-size: 12px;
        padding-inline-start: 8px;
        padding-top: 4px;
        text-align: start;
    }

    label {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 300;
        position: relative;
        width: 100%;
        text-align: start;
        position: relative;
        span{
            position: absolute;
            inset-inline-end: 0px;
            font-size: 15px;
            color: #948e8e;
            top: 2px;
        }

        @media (max-width:768px) {
            font-size: 14px;
        }

    }

    .ant-input {
        margin-bottom: 0px;
    }

    &.required {
        padding-inline-start: 2px;

        label {
            padding-inline-start: 10px;

            &:after {
                content: '*';
                position: absolute;
                top: -3px;
                inset-inline-start: 0px;
                color: #f01212;
            }
        }
    }
}
.ant-modal.ant-modal-confirm{
    width: fit-content !important;
    .ant-modal-body{
        padding: 0px !important;
    }
}
.search-input{
    padding-inline-end: 20px;
    input{
        padding: 0px;
        height: 40px;
        margin: 0px;
        border-radius: 10px;
        padding: 5px 10px;
        width: 300px;
        background: #2c50aa;
        padding-top: 8px;
        color:#fff;
        @media (max-width:768px) {
            color: #000;
            background: #fff;
            border-color: #2c50aa;

        }
        &:hover , &:focus{
            border-color: #fff; 
            color:#fff;
        }
    }
}
.filters{
    .anticon.anticon-plus-circle svg{
        @media (max-width:768px) {
            color: #2c50aa !important;
        }
    }
}

.ant-input {
    height: 67px; // all input  height 67px
    border: 1px solid #E9E9F0;
    border-radius: 20px;
    font-size: 14px;
    padding: 23px 30px;
    margin-bottom: 24px;

    @media (max-width:768px) {
        height: 58px;
    }
}

/// customize button to be rounded 
.ant-btn-round.ant-btn-primary {
    height: 65px !important;
    border-radius: 20px !important;
    padding: 0px 70px !important;
    font-size: 18px;
}

nz-space {
    width: 100%;
}


/// customize modal 
.ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal {
        top: auto;
        background: #F0F4F9;
        padding-bottom: 0px;
        padding: 30px;
        border-radius: 20px;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 768px) {
            width: 100% !important;
            background: transparent !important;
            padding: 5px !important;
        }

        .ant-modal-content {
            border-radius: 20px;
            overflow: hidden;
            padding: 30px;

            @media screen and (max-width: 768px) {
                padding: 15px !important;
                width: 100%;
            }
        }

        .ant-modal-body {
            @media screen and (max-width: 768px) {
                padding: 15px;
            }
        }

        .ant-modal-title {
            color: #2B50AA;
            font-size: 18px;
            position: relative;
            top: 3px;
        }

        .ant-modal-footer,
        .ant-modal-header {
            padding: 0px;
        }

        .ant-modal-header {
            padding-bottom: 20px;
            border-bottom-color: #E9E9F0;
        }

        .ant-modal-footer {
            border: 0px;
        }

        .ant-btn {
            height: 65px !important;
            border-radius: 20px !important;
            padding: 0px 70px !important;
            font-size: 18px;

            @media screen and (max-width: 768px) {
                height: 56px !important;
            }
        }
        .ant-modal-confirm-body{
            display: flex;
            .anticon{
                margin-right: 0px;
                margin-inline-end: 10px;
            }
        }

        button.ant-modal-close {
            background: #B53030;
            color: #fff;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 27px;
            inset-inline-end: 30px;
            right: auto;

            @media screen and (max-width: 768px) {
                top: 15px !important;
               inset-inline-end: 15px !important;
            }

            svg {
                width: 11px;
                height: 22px;
                position: relative;
                top: 6px;
            }
        }
    }
}

.ant-collapse {
    border: 0px;

    .ant-collapse-item {
        .ant-collapse-header {
            background: #fff;
            flex-flow: row-reverse;
            align-items: center;
            justify-content: start;
            padding-inline-start: 20px;
            color: #2B50AA;
            font-size: 20px;
            padding-inline-start: 50px;
            padding-top: 25px;
            border-bottom-color: #c2cbe5;
            padding-bottom: 20px;
            padding-inline-end: 0px !important;

            &:before {
                content: "";
                width: 28px;
                height: 4px;
                border-radius: 10px;
                background: #2B50AA;
                display: flex;
                position: absolute;
                inset-inline-start: 0px;
                top: 36px;
            }

            .ant-collapse-arrow {
                position: relative;
                top: 0px;
                inset-inline-end:0px;
               inset-inline-start: auto;
                margin: 0;
                transform: inherit;
                padding-inline-start: 20px;
                font-size: 18px;
            }
        }

        &:last-child {
            border: 0px;
        }
    }

    .ant-collapse-content-box {
        padding: 0px;
    }

    .ant-collapse-content {
        border: 0px;
    }
}


.desktop {
    display: flex !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.mobile {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: flex !important;
    }
}

// nz-header , nz-sider{
//     @media screen and (max-width: 768px) {
//         display: none  !important;
//       } 
// }

nz-content {
    @media screen and (max-width: 768px) {
        margin: 0px !important;
        background: #fff !important;
    }
}

nz-sider {
    @media screen and (max-width: 768px) {
        overflow: inherit !important;
        z-index: 123;
        position: fixed !important;
        top: 0px !important;
        height: 100%;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0px !important;
    }

    .ant-layout-sider-zero-width-trigger {
        @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 142px;
        }
    }

    .anticon.anticon-bars {
        @media screen and (max-width: 768px) {
            background: url("./assets/svg/vuesax-linear-arrow-circle-right.svg");
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        svg {
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }

    .ant-layout-sider-children {
        @media screen and (max-width: 768px) {
            width: unset !important;
            display: unset !important;
            align-items: center;
        }
    }

    &.ant-layout-sider-collapsed {
        .anticon.anticon-bars {
            @media screen and (max-width: 768px) {
                background: url("./assets/svg/vuesax-linear-arrow-circle-left.svg");
            }
        }
    }
}
  [dir="rtl"] .ant-layout-sider-zero-width-trigger {
    @media screen and (max-width: 768px) {
      right: 3px; /* Adjust position for RTL layout */
      left: unset;
      .ant-layout-sider-collapsed {
         margin-right: 123px;
      }
    }
    
  }
  [dir="rtl"] .ant-layout-sider-zero-width-trigger[ng-reflect-nz-collapsed="false"] {
    margin-right: 123px;
}
.ant-layout-sider-zero-width-trigger {
    @media screen and (max-width: 768px) {
        background: #fff;
        color: #2b51aa;
    }
}

.ant-layout-header {
    @media screen and (max-width: 768px) {
        padding: 0px 10px !important;
    }
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    border: none !important;
    box-shadow: none !important;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon{
    margin: 0px 8px;
}

.rtl .custom-table {
    .ant-table-pagination.ant-pagination{
        ul{
            display: flex !important;
            flex-direction: row-reverse !important;
        }
    }
}

.pac-container {
    top: 748px !important;
  }
  .pac-container{
    top: 748px !important;
    @media (max-width: 768px) {
        top: 341px !important;

      }

  }






  /* Global styles or specific to a component */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the loader is on top */
  }
  
  .nz-spin {
    /* Additional styles for the spinner */
    color: white; /* Spinner color */
    font-size: 3rem; /* Spinner size */
  }
  
  /* Add animations for a more dynamic look */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .nz-spin svg {
    animation: spin 1s linear infinite; /* Smooth spinning effect */
  }
  